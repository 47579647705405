import React, { useState, useEffect } from 'react';
import './inicial.css';
import Footer from '../components/footer/footer';
import EncabezadoSincarrusel from '../components/encabezado/encabezado_sincarrusel';

function Inicial() {
  const [novedades, setNovedades] = useState([]);

  useEffect(() => {
    fetch('https://frenoux1.pythonanywhere.com/api/productos')
      .then((response) => response.json())
      .then((data) => {
        console.log('Datos de data.json:', data);

        // Filtra las novedades por la categoría "inicial"
        const novedadesFiltradas = data.filter((novedad) =>
          novedad.categoria.toLowerCase() === 'inicial'
        );

        setNovedades(novedadesFiltradas);
      })
      .catch((error) => console.error('Error al obtener datos:', error));
  }, []);


  const handleMoreInfoClick = (id) => {
    // Abre la página en una nueva pestaña
    window.open(`/pinicial/${id}`, '_blank');
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Se suma 1 porque los meses en JavaScript van de 0 a 11
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="Inicial">
      <EncabezadoSincarrusel />

      <div>
        <br /><br />
        <h2 className="text-center mb-4">NIVEL INICIAL</h2>
        <section id="servicios" className="py-5">
          <div className="container">
            <h2 className="text-center mb-4">Novedades</h2>
            <div className="row">
              {novedades.map((novedad) => (
                <div className="col-lg-3 mb-4" key={novedad.id}>
                  <div className="card">
                    {/* Mostrar 'nombre' encima de la tarjeta */}
                    <div className="card-header">
                      <h5>{novedad.nombre}</h5>
                    </div>
                    <img
                      src={`https://frenoux1.pythonanywhere.com/static/assets/fotos_productos/${novedad.imagen}`}
                      className="card-img-top servicio-img"
                      alt={`Novedad ${novedad.id}`}
                    />

                    <div className="card-footer">
                      <p>ID: {novedad.id}</p>
                      <p>Fecha de Publicación: {formatDate(novedad.fecha_publicacion)}</p>
                      <p>Categoría: {novedad.categoria}</p>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleMoreInfoClick(novedad.id)}
                      >
                        Más Información
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Inicial;
