import React, { useState, useEffect } from 'react';
import '../paginas/inicial.css';

function ComponenteHome() {
  const [novedades, setNovedades] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const novedadesPerPage = 4; // Número de novedades por página
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://frenoux1.pythonanywhere.com/api/productos')
      .then((response) => response.json())
      .then((data) => {
        console.log('Datos de la API:', data);
        setNovedades(data); // Suponiendo que la estructura de datos es directamente el array de "novedades"
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener datos:', error);
        setError('Error al obtener datos. Por favor, inténtalo de nuevo más tarde.');
        setLoading(false);
      });
  }, []);

  const indexOfLastNovedad = currentPage * novedadesPerPage;
  const indexOfFirstNovedad = indexOfLastNovedad - novedadesPerPage;
  const currentNovedades = novedades.slice(indexOfFirstNovedad, indexOfLastNovedad);

  const handleMoreInfoClick = (id) => {
    // Abre la página en una nueva pestaña
    window.open(`/pcomponenteHome/${id}`, '_blank');
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Se suma 1 porque los meses en JavaScript van de 0 a 11
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="Inicial">
      <div>
        <br /><br />
        <h2 className="text-center mb-4">TODAS NUESTRAS NOVEDADES</h2>
        <section id="servicios" className="py-5">
          <div className="container">
            <div className="row">
              {currentNovedades.map((novedad) => (
                <div className="col-lg-3 mb-4" key={novedad.id}>
                  <div className="card">
                    <div className="card-header">
                      <h5>{novedad.nombre}</h5>
                    </div>
                    <img
                      src={`https://frenoux1.pythonanywhere.com/static/assets/fotos_productos/${novedad.imagen}`}
                      className="card-img-top servicio-img"
                      alt={`Novedad ${novedad.id}`}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{novedad.titulo}</h5>
                    </div>
                    <div className="card-footer">
                      <p>ID: {novedad.id}</p>
                      <p>Fecha de Publicación: {formatDate(novedad.fecha_publicacion)}</p>
                      <p>Categoría: {novedad.categoria}</p>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleMoreInfoClick(novedad.id)}
                      >
                        Más Información
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="pagination">
              {Array.from({ length: Math.ceil(novedades.length / novedadesPerPage) }).map((_, index) => (
                <button key={index + 1} onClick={() => paginate(index + 1)}>
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ComponenteHome;
