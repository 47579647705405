import React from 'react';
import './encabezado.css';  // Importa el archivo CSS
import cabecera1 from './imagenes/cabecera1.png';

// Definición de un componente funcional
const EncabezadoSincarrusel = () => {
  return (
    <div>
        <div className="cabecera">
            <a href="/contacto" target="_blank" rel="noopener noreferrer">
                <img src={cabecera1} alt="Cabecera" />
            </a>
        </div>
        
        
        <div className="quick-navigation">
        <div className="nav-item">
          <a href="/" className="nav-item-link">
            <div className="nav-item-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
              </svg>
            </div>
            <div className="nav-item-text">Inicio</div>
          </a>
        </div>
        <div className="nav-item">
          <a href="/inicial" className="nav-item-link">
            <div className="nav-item-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M2 17h20v2H2zm11.84-9.21c-.15-.15-.32-.28-.51-.38-.68-.37-1.57-.36-2.25.01-.16.09-.31.2-.45.33l-3.81 3.81c-.41.41-.62.98-.62 1.53s.21 1.12.62 1.53l3.79 3.79c.41.41.97.62 1.52.62s1.11-.21 1.53-.62l3.81-3.81c.09-.14.17-.29.23-.45.37-.68.38-1.57.01-2.25-.1-.19-.23-.36-.38-.51l-3.8-3.81z"/>
              </svg>
            </div>
            <div className="nav-item-text">Nivel Inicial</div>
          </a>
        </div>
        <div className="nav-item">
          <a href="/primaria" className="nav-item-link">
            <div className="nav-item-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 1h4v1h-4V3zm7 16H7V5h10v14zm-5-1h2v-1h-2v1zm-3-1h2v-1H9v1zm6 0h2v-1h-2v1zm-3-2h2v-1h-2v1zm3 0h2v-1h-2v1zm-6 0h2v-1H9v1z"/>
              </svg>
            </div>
            <div className="nav-item-text">Nivel Primario</div>
          </a>
        </div>
        <div className="nav-item">
          <a href="/secundaria" className="nav-item-link">
            <div className="nav-item-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M5 13.18v2.81c0 .73.4 1.41 1.04 1.76l5 2.73c.6.33 1.32.33 1.92 0l5-2.73c.64-.35 1.04-1.03 1.04-1.76v-2.81l-5.96 3.45c-.6.35-1.32.35-1.92 0L5 13.18zm5-2.48L2.5 8.27c-.31-.18-.5-.5-.5-.85s.19-.67.5-.85l8.5-4.92c.6-.35 1.32-.35 1.92 0l8.5 4.92c.31.18.5.5.5.85s-.19.67-.5.85L13 10.7V15c0 .37-.1.72-.28 1.03l-1.72-1L5 13.18V10.7z"/>
              </svg>
            </div>
            <div className="nav-item-text">Nivel Secundario</div>
          </a>
        </div>
        <div className="nav-item">
          <a href="/administracion" className="nav-item-link">
            <div className="nav-item-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M22 9V7h-2V5c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-2h2v-2h-2v-2h2v-2h-2V9h2zm-4 10H4V5h14v14zm-6-2h2v-2h-2v2zm-2 0h2v-2h-2v2zm-2 0h2v-2H8v2z"/>
              </svg>
            </div>
            <div className="nav-item-text">Administración</div>
            
          </a>
        </div>
        <div className="nav-item">
  <a href="/contacto" className="nav-item-link">
    <div className="nav-item-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M2 4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H2zm0 2h20v12H2V6zm10 6L4 8v8l8-4 8 4V8l-8 4z"/>
      </svg>
    </div>
    <div className="nav-item-text">Contacto</div>
  </a>
</div>
      </div>

    </div>
  );
};

export default EncabezadoSincarrusel;
